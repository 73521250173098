<template>
  <div class="">
    <v-container class="page-title">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.siteDetails.analytics.title') }}</h4>
          <div class="ml-auto">
            <select-dropdown
              :items="periods"
              :value="period"
              :itemText="'text'"
              :itemValue="'value'"
              :size="'lg'"
              @update:value="changePeriod"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="px-0">
      <v-row class="responsive-row justify-stretch">
        <template v-if="!noData">
          <monitoring-visitors-card
            :loading="visitsChart.loading"
            :data="normalizedVisitorsChartData"
            :title="visitsChart.title"
            :dataIndicators="visitsChart.dataIndicators"
          />

          <shares-card
            v-if="visitsByCountryChart.data.length > 0"
            :loading="visitsByCountryChart.loading"
            :title="visitsByCountryChart.title"
            :data="visitsByCountryChart.data"
          />

          <shares-card
            v-if="visitsByPageChart.data.length > 0"
            :loading="visitsByPageChart.loading"
            :title="visitsByPageChart.title"
            :data="visitsByPageChart.data"
          />

          <shares-card
            v-if="visitsByContinentChart.data.length > 0"
            :loading="visitsByContinentChart.loading"
            :title="visitsByContinentChart.title"
            :data="visitsByContinentChart.data"
          />

          <shares-card
            v-if="visitsByRegionChart.data.length > 0"
            :loading="visitsByRegionChart.loading"
            :title="visitsByRegionChart.title"
            :data="visitsByRegionChart.data"
          />

          <shares-card
            v-if="visitsByReferrerChart.data.length > 0"
            :loading="visitsByReferrerChart.loading"
            :title="visitsByReferrerChart.title"
            :data="visitsByReferrerChart.data"
          />

          <doughnut-chart-card
            v-if="visitsByDeviceChart.data.length > 0"
            :loading="visitsByDeviceChart.loading"
            :title="visitsByDeviceChart.title"
            :data="visitsByDeviceChart.data"
          />

          <pie-chart-card
            v-if="visitsByDeviceBrandChart.data.length > 0"
            :loading="visitsByDeviceBrandChart.loading"
            :title="visitsByDeviceBrandChart.title"
            :data="visitsByDeviceBrandChart.data"
          />

          <pie-chart-card
            v-if="visitsByDeviceOsChart.data.length > 0"
            :loading="visitsByDeviceOsChart.loading"
            :title="visitsByDeviceOsChart.title"
            :data="visitsByDeviceOsChart.data"
          />

          <pie-chart-card
            v-if="visitsByDeviceBrowserChart.data.length > 0"
            :loading="visitsByDeviceBrowserChart.loading"
            :title="visitsByDeviceBrowserChart.title"
            :data="visitsByDeviceBrowserChart.data"
          />
        </template>

        <template v-else>
          <v-col cols="12" class="responsive-card">
            <v-card elevation="4" class="monitoring-card">
              <TableFullScreenMessage
                  :title="$t('message.emptyTable.monitoring.title')"
                  :desc="$t('message.emptyTable.monitoring.description')"
                >
                  <template v-slot:image>
                    <HostingDatabasesIllustration />
                  </template>
                </TableFullScreenMessage>
              </v-card>
            </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DoughnutChartCard from "../../components/cards/monitoring/DoughnutChartCard.vue";
import PieChartCard from "../../components/cards/monitoring/PieChartCard.vue";
import MonitoringVisitorsCard from "../../components/cards/monitoring/MonitoringVisitorsCard.vue";
import SharesCard from "../../components/cards/monitoring/SharesCard.vue";
import SelectDropdown from "../../components/SelectDropdown.vue";
import HostingDatabasesIllustration from "../../components/illustrations/hosting/hosting-databases-illustration.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";

import normalizeChartData from "../../mixins/ChartDataNormalizerMixin";

import Api from "@/apis/Api";

export default {
  components: {
    MonitoringVisitorsCard,
    DoughnutChartCard,
    PieChartCard,
    SharesCard,
    SelectDropdown,
    TableFullScreenMessage,
    HostingDatabasesIllustration
  },
  mixins: [normalizeChartData],
  data: function () {
    return {
      fetching: true,
      data: {},
      applicationId: this.$route.params.id,
      periods: [
        { value: "last-week", text: this.$t('form.option.period.lastWeek') },
        { value: "last-month", text: this.$t('form.option.period.lastMonth') },
        { value: "last-year", text: this.$t('form.option.period.lastYear') },
      ],
      period: "last-week",

      visitsChart: {
        loading: true,
        data: {
          labels: [],
          datasets: [],
        },
        title: this.$t('heading.instance.monitoring.chart.visitors'),
        dataIndicators: [],
      },
      visitsByPageChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.page'),
      },
      visitsByCountryChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.country'),
      },
      visitsByContinentChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.continent'),
      },
      visitsByRegionChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.region'),
      },
      visitsByReferrerChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.referrer'),
      },
      visitsByDeviceChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.device'),
      },
      visitsByDeviceBrandChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.deviceBrand'),
      },
      visitsByDeviceOsChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.os'),
      },
      visitsByDeviceBrowserChart: {
        loading: true,
        data: [],
        title: this.$t('heading.instance.monitoring.chart.visits.browser'),
      },
      pieChartColors: ["#83CC80", "#44AA92", "#D45858", "#EF9840", "#CA7699"],
    };
  },
  computed: {
    isMatomoEnabled: function () {
      return this.$store.state.home.app.matomo_enabled;
    },
    noData: function () {
      return (
        (this.visitsChart.data.datasets.length == 0 ||
          this.visitsChart.data.datasets[0].filter((data) =>
            data ? true : false
          ).length == 0) &&
        !this.fetching &&
        !this.visitsChart.loading
      );
    },
    normalizedVisitorsChartData: function () {
      return this.normalizeChartData(this.visitsChart.data);
    },
  },
  mounted() {
    this.fetchVisitors();
    this.fetchVisitsByPage();
    this.fetchVisitorsByCountry();
    this.fetchVisitorsByContinent();
    this.fetchVisitorsByRegion();
    this.fetchVisitorsByReferrer();
    this.fetchVisitorsByDevice();
    this.fetchVisitorsByDeviceBrand();
    this.fetchVisitorsByDeviceOs();
    this.fetchVisitorsByDeviceBrowser();
    this.fetching = false;
  },
  props: {
    instance: Object,
  },
  methods: {
    changePeriod(period) {
      // FIXME select-dropdown emits update twice
      if (this.period == period.value) {
        return;
      }

      this.period = period.value;
      this.fetchVisitors();
      this.fetchVisitsByPage();
      this.fetchVisitorsByCountry();
      this.fetchVisitorsByContinent();
      this.fetchVisitorsByRegion();
      this.fetchVisitorsByReferrer();
      this.fetchVisitorsByDevice();
      this.fetchVisitorsByDeviceBrand();
      this.fetchVisitorsByDeviceOs();
      this.fetchVisitorsByDeviceBrowser();
    },
    fetchVisitsByPage() {
      this.visitsByPageChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visits-by-page/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let page of response.data.data) {
            data.push({
              label: page.label,
              data: page.visits,
            });
          }

          // data.sort((a,b) => (a.data > b.data) ? -1 : ((b.data > a.data) ? 1 : 0));

          this.visitsByPageChart.data = data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.visitsByPageChart.loading = false;
        });
    },
    fetchVisitorsByCountry() {
      this.visitsByCountryChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-country/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let record of response.data.data) {
            data.push({
              label: record.label,
              data: record.visits,
              img: this.getFlagImg(record.code),
            });
          }
          this.visitsByCountryChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByCountryChart.loading = false;
        });
    },
    fetchVisitorsByContinent() {
      this.visitsByContinentChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-continent/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let record of response.data.data) {
            data.push({
              label: record.label,
              data: record.visits,
            });
          }
          this.visitsByContinentChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByContinentChart.loading = false;
        });
    },
    fetchVisitorsByRegion() {
      this.visitsByRegionChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-region/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let record of response.data.data) {
            data.push({
              label: record.label,
              data: record.visits,
              img: this.getFlagImg(record.country_code),
            });
          }
          this.visitsByRegionChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByRegionChart.loading = false;
        });
    },
    fetchVisitorsByReferrer() {
      this.visitsByReferrerChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-referrer/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let record of response.data.data) {
            data.push({
              label: record.label,
              data: record.visits,
            });
          }
          this.visitsByReferrerChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByReferrerChart.loading = false;
        });
    },
    fetchVisitorsByDevice() {
      this.visitsByDeviceChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-device/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let [i, record] of response.data.data.entries()) {
            data.push({
              label: record.label,
              data: record.visits,
              color: this.pieChartColors[i],
            });
          }
          this.visitsByDeviceChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByDeviceChart.loading = false;
        });
    },
    fetchVisitorsByDeviceBrand() {
      this.visitsByDeviceBrandChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-device-brand/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let [i, record] of response.data.data.entries()) {
            data.push({
              label: record.label,
              data: record.visits,
              color: this.pieChartColors[i],
            });
          }
          this.visitsByDeviceBrandChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByDeviceBrandChart.loading = false;
        });
    },
    fetchVisitorsByDeviceOs() {
      this.visitsByDeviceOsChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-device-os/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let [i, record] of response.data.data.entries()) {
            data.push({
              label: record.label,
              data: record.visits,
              color: this.pieChartColors[i],
            });
          }
          this.visitsByDeviceOsChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByDeviceOsChart.loading = false;
        });
    },
    fetchVisitorsByDeviceBrowser() {
      this.visitsByDeviceBrowserChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors-by-device-browser/${this.period}`
      )
        .then((response) => {
          let data = [];
          for (let [i, record] of response.data.data.entries()) {
            data.push({
              label: record.label,
              data: record.visits,
              color: this.pieChartColors[i],
            });
          }
          this.visitsByDeviceBrowserChart.data = data;
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsByDeviceBrowserChart.loading = false;
        });
    },
    fetchVisitors() {
      this.visitsChart.loading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors/${this.period}`
      )
        .then((response) => {
          if (!response.data.data) {
            return;
          }
          let labels = [];
          let dataset = [];
          for (let [date, amount] of Object.entries(
            response.data.data.visits.records
          )) {
            labels.push(date);
            dataset.push(amount);
          }
          this.visitsChart.data.labels = labels;
          this.visitsChart.data.datasets = [dataset];
          let delta =
            response.data.data.visits.previous_total != 0
              ? parseFloat(
                  (response.data.data.visits.total /
                    response.data.data.visits.previous_total -
                    1) *
                    100
                ).toFixed(2)
              : 100;
          this.visitsChart.dataIndicators = [
            {
              color: false,
              label: "Total",
              delta: `${delta}%`,
              value: response.data.data.visits.total,
            },
          ];
        })
        .catch(() => {
          // console.error(Api.getErrorMessage(error));
        })
        .finally(() => {
          this.visitsChart.loading = false;
        });
    },
    getFlagImg(code) {
      if (code.toUpperCase() == "XX") {
        return `img/flags/${code.toUpperCase()}.svg`;
      }
      return `img/flags/${code.toUpperCase()}.png`;
    },
  },
};
</script>
